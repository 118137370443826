import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import Badge from '@/components/Badge';
import type { ProductLineItem } from '@/components/DeliveryWeekOverview';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import type { PropType, VNode } from 'vue';
export default defineComponent({
  name: 'DeliveryWeekOverviewTable',
  props: {
    discountLines: {
      default: () => [],
      type: Array as PropType<ProductLineItem[]>,
    },
    productLines: {
      default: () => [],
      type: Array as PropType<ProductLineItem[]>,
    },
    total: {
      required: true,
      type: Number,
    },
    totalPreDiscounts: {
      required: true,
      type: Number,
    },
    class: {
      default: '',
      type: String,
    },
  },
  setup() {
    const { isSelectedDeliveryWeekEditable, isSelectedDeliveryWeekPaused } =
      useDeliveryWeeks();

    const { formatPrice } = useCustomerCurrency();

    return {
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      formatPrice,
    };
  },
  render(): VNode {
    const groupedProductLines = this.productLines.reduce(
      (acc, item) => {
        if (!acc[item.label]) {
          acc[item.label] = { ...item, quantity: 1 };
        } else {
          acc[item.label].price = {
            ...acc[item.label].price,
            netPrice: acc[item.label].price.netPrice + item.price.netPrice,
            grossPrice:
              acc[item.label].price.grossPrice + item.price.grossPrice,
          };
          acc[item.label].quantity += 1;
        }
        return acc;
      },
      {} as Record<string, ProductLineItem & { quantity: number }>
    );

    return (
      <table class={`w-full text-right text-base leading-8 ${this.class}`}>
        {Object.values(groupedProductLines).map(
          ({ label, price, quantity }) => {
            return (
              <tr class="product-item">
                <td class="text-left">
                  {quantity > 1 ? `${quantity} x ` : ''}
                  {label}
                </td>
                <td>
                  {price.hasPriceModifier() ? (
                    <span class="text-rb-light-gray line-through">
                      {this.formatPrice(price.grossPrice)}
                    </span>
                  ) : (
                    ''
                  )}
                </td>
                <td>{this.formatPrice(price.netPrice)}</td>
              </tr>
            );
          }
        )}
        {this.discountLines.map(({ label, price, type }) => (
          <tr
            class={[
              'w-20',
              {
                'text-rb-bright-red': this.isSelectedDeliveryWeekPaused,
              },
            ]}
          >
            <td class="text-left">{label}</td>
            <td />
            <td class="price">
              {type === 'campaign' ? (
                <Badge
                  type={
                    this.isSelectedDeliveryWeekPaused ? 'caution' : 'success'
                  }
                  v-slots={{ icon: () => <IconCampaign class="icon" /> }}
                  class="mb-1"
                >
                  -{this.formatPrice(price.discount + price.credit)}
                </Badge>
              ) : (
                `-${this.formatPrice(price.discount + price.credit)}`
              )}
            </td>
          </tr>
        ))}
        <tr class="border-rb-light-gray border-b-1 border-t-1 h-8 text-lg font-medium">
          <td class="py-1 text-left font-medium uppercase">
            {this.$t('delivery_week_overview.total')}
          </td>
          <td class="price text-rb-light-gray py-1 line-through">
            <span hidden={this.totalPreDiscounts === this.total}></span>
            {this.formatPrice(this.totalPreDiscounts)}
          </td>
          <td class="price py-1 font-bold">{this.formatPrice(this.total)}</td>
        </tr>
      </table>
    );
  },
});

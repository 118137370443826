import LoyaltyProgramBannerActivate from '@/components/LoyaltyProgramBanner/LoyaltyProgramBannerActivate';
import LoyaltyProgramProgressBar, {
  loyaltyLevels,
} from '@/components/LoyaltyProgramProgressBar';
import { getLoyaltyDiscountPercentage } from '@ruokaboksi/utils';
import type { VNode } from 'vue';
import { NuxtLink } from '#components';

export default defineComponent({
  name: 'LoyaltyProgramBanner',
  setup() {
    const { localeProperties } = useI18n();

    const { currentCustomer } = useCurrentCustomer();
    const { selectedDeliveryWeek } = useDeliveryWeeks();
    const loyaltyLogo = computed(() =>
      getLoyaltyLogo(localeProperties.value.brand)
    );

    return {
      currentCustomer,
      selectedDeliveryWeek,
      loyaltyLogo,
    };
  },
  render(): VNode | null {
    const loyaltyLevel =
      this.selectedDeliveryWeek?.loyaltyLevel ??
      this.currentCustomer?.loyaltyLevel ??
      1;

    const weekPaused = this.selectedDeliveryWeek?.paused ?? false;

    return (
      <div class="max-w-100 mx-auto h-14 rounded-sm bg-[url('/assets/images/loyalty_banner.webp')] bg-cover">
        <NuxtLink
          to={{ name: 'more-offers' }}
          class="flex w-full justify-between !p-2 !no-underline !opacity-100"
        >
          {!this.currentCustomer?.inLoyaltyProgram ? (
            <LoyaltyProgramBannerActivate />
          ) : (
            <>
              <div class="max-w-72 flex-1">
                <div class="xs:flex-row mb-1 flex flex-col gap-2">
                  {this.loyaltyLogo && <this.loyaltyLogo height="1rem" />}
                  <span class="text-xs text-white">
                    {this.$t('loyalty_program.level', { level: loyaltyLevel })}
                    {this.$t(loyaltyLevels[loyaltyLevel - 1].titleKey)}
                  </span>
                </div>
                <div class="xs:block hidden">
                  <LoyaltyProgramProgressBar
                    loyaltyLevel={loyaltyLevel}
                    weekPaused={weekPaused}
                  />
                </div>
              </div>
              <div
                class={`bg-rb-yellow text-rb-dark-green grid gap-0 rounded-sm px-3 text-center ${
                  weekPaused ? 'text-rb-light-gray !bg-white' : ''
                }`}
              >
                <p class="text-lg font-bold leading-6">
                  {getLoyaltyDiscountPercentage(loyaltyLevel)}%
                </p>
                <p class="text-tiny">
                  {this.$t('loyalty_program.discount_per_box')}
                </p>
              </div>
            </>
          )}
        </NuxtLink>
      </div>
    );
  },
});

import Dropdown from '@/components/Dropdown';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import useDeliveriesApi from '@/composables/useDeliveriesApi';
import type { DeliveryWeekWithFormattedDays } from '@/models';
import { getExceptionDeliveryDate } from '@/utils/exceptionDeliveryDates';
import {
  type DeliverySlot,
  type Language,
  defaultLanguage,
} from '@ruokaboksi/api-client';
import {
  Week,
  type currencyMap,
  formatDateToShortWeekDayDate,
  formatDeliverySlotTimeWindow,
  formatPrice,
} from '@ruokaboksi/utils';
import IconCalendar from 'assets/icons/icon-calendar.svg?component';

const getDeliveryDate = (
  deliverySlot: DeliverySlot,
  selectedDeliveryWeek: DeliveryWeekWithFormattedDays,
  locale: Language,
  currency: keyof typeof currencyMap
) => {
  const deliveryDate = Week.fromPaymentDate(
    new Date(selectedDeliveryWeek?.paymentDate),
    deliverySlot
  ).deliveryDate();

  const exceptionDeliveryDate = getExceptionDeliveryDate(deliveryDate);

  const deliveryDateText = `${formatDateToShortWeekDayDate(
    exceptionDeliveryDate ?? deliveryDate,
    locale
  )}${locale === 'fi' ? ' klo ' : ', '}${formatDeliverySlotTimeWindow(
    deliverySlot
  )}`;

  const deliveryDateTextCapitalized =
    deliveryDateText[0].toUpperCase() + deliveryDateText.slice(1);

  return {
    text: deliveryDateTextCapitalized,
    caption: deliverySlot.price
      ? `+${formatPrice(deliverySlot.price, currency)}`
      : undefined,
  };
};

export default defineComponent({
  name: 'DeliverySlotDropdown',

  setup() {
    const { subscription } = useCustomerSubscriptions();

    const { currency } = useCustomerCurrency();

    const { getAvailableDeliverySlots, changeDeliverySlotForDeliveryPlan } =
      useDeliveriesApi();
    const { selectedDeliveryWeek } = useDeliveryWeeks();
    const { t, locale } = useI18n();
    const noticeStore = useNoticeStore();

    const { data: deliverySlots } = getAvailableDeliverySlots(subscription);

    const weekHasExceptionDeliveryDate = computed(() => {
      if (!selectedDeliveryWeek.value) return undefined;
      return deliverySlots.value?.some((slot) => {
        return getExceptionDeliveryDate(
          Week.fromPaymentDate(
            new Date(selectedDeliveryWeek.value?.paymentDate ?? ''),
            slot
          ).deliveryDate()
        );
      });
    });

    const handleChange = async (newDeliverySlot: number) => {
      if (subscription.value && selectedDeliveryWeek.value) {
        try {
          await changeDeliverySlotForDeliveryPlan(
            subscription.value.id,
            new Date(selectedDeliveryWeek.value.paymentDate),
            newDeliverySlot
          );
          noticeStore.addNotice({
            text: t('delivery_dropdown.success_message'),
            type: 'success',
          });
        } catch (_error) {
          noticeStore.addNotice({
            text: t('delivery_dropdown.failed_message'),
            type: 'warning',
          });
        }
      }
    };

    return {
      deliverySlots,
      selectedDeliveryWeek,
      changeDeliverySlotForDeliveryPlan,
      weekHasExceptionDeliveryDate,
      subscription,
      locale,
      handleChange,
      currency,
    };
  },

  render() {
    if (
      !this.deliverySlots ||
      this.deliverySlots.length <= 1 ||
      !this.subscription
    )
      return null;

    return (
      <div class="my-5 flex flex-col">
        <span class="my-1 font-medium">
          {this.$t('delivery_day_dropdown.heading')}
        </span>
        <Dropdown
          disabled={
            this.selectedDeliveryWeek?.paused ||
            !this.selectedDeliveryWeek?.editable ||
            this.weekHasExceptionDeliveryDate
          }
          options={this.deliverySlots.map((slot) => {
            const deliveryDate = this.selectedDeliveryWeek
              ? getDeliveryDate(
                  slot,
                  this.selectedDeliveryWeek,
                  this.locale as Language,
                  this.currency as keyof typeof currencyMap
                )
              : { text: slot.deliveryDay, caption: undefined };
            return {
              id: slot.id,
              text: deliveryDate.text,
              caption: deliveryDate.caption,
            };
          })}
          modelValue={
            this.selectedDeliveryWeek?.deliverySlotId ??
            this.subscription.defaultDeliverySlotId
          }
          onChange={this.handleChange}
          v-slots={{
            icon: () => <IconCalendar aria-hidden="true" class="h-6 w-6" />,
          }}
        />
      </div>
    );
  },
});

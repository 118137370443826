import ToggleSwitch from '@/components/ToggleSwitch';
import type { VNode } from 'vue';
import './PauseToggle.css';

export default defineComponent({
  name: 'SelectedWeekPauseToggle',
  setup() {
    const { subscriptionId, isSubscriptionActive } = useCustomerSubscriptions();
    const { setWeekPause } = useDeliveriesApi();
    const {
      deliveryWeeks,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPauseEditable,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
    } = useDeliveryWeeks();
    const noticeStore = useNoticeStore();
    const { t } = useI18n();

    const handleToggle = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      const newValue = input.checked;

      if (!isSelectedDeliveryWeekPauseEditable.value) return;
      if (!subscriptionId.value || !selectedDeliveryWeek.value) {
        return;
      }

      try {
        await setWeekPause(
          newValue,
          subscriptionId.value,
          new Date(selectedDeliveryWeek.value.paymentDate)
        );
      } catch (_error) {
        return noticeStore.addNotice({
          text: t('week_banner.week_x_update_failed', {
            weekNumber: selectedDeliveryWeekNumberString.value,
          }),
          type: 'caution',
        });
      }

      const areTwoOtherWeeksPaused = deliveryWeeks.value
        ?.filter((week) => week.editable)
        .slice(0, 3)
        .filter(
          (week) => week.paymentDate !== selectedDeliveryWeek.value?.paymentDate
        )
        .every((el) => el.paused);

      if (areTwoOtherWeeksPaused && newValue && isSubscriptionActive.value) {
        noticeStore.addNotice({
          text: t('week_banner.all_weeks_paused'),
          timeout: 10000,
        });
      }
    };

    const toggleValue = computed<boolean>(
      () => selectedDeliveryWeek.value?.paused || false
    );

    const toggleTextKey = computed<string>(() => {
      if (!isSelectedDeliveryWeekEditable.value)
        return toggleValue.value
          ? 'week_banner.week_x_was_paused'
          : 'week_banner.week_x_was_active';
      return toggleValue.value
        ? 'week_banner.activate_week_x'
        : 'week_banner.pause_week_x';
    });

    return {
      handleToggle,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPauseEditable,
      selectedDeliveryWeekNumberString,
      toggleTextKey,
      toggleValue,
    };
  },
  render(): VNode {
    return (
      <fieldset class="pause-toggle">
        <ToggleSwitch
          align="right"
          checked={this.toggleValue}
          disabled={!this.isSelectedDeliveryWeekPauseEditable}
          handleToggle={this.handleToggle}
        >
          {this.$t(this.toggleTextKey, {
            weekNumber: this.selectedDeliveryWeekNumberString,
          })}
        </ToggleSwitch>
      </fieldset>
    );
  },
});

import placeholderImageUrl from '@/assets/images/placeholder-card-image.png';
import ProductPrice from '@/components/ProductPrice';
import SanityPicture from '@/components/SanityPicture';
import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'SelectedProductsTable',
  props: {
    discount: {
      default: 0,
      type: Number,
    },
    index: {
      default: 0,
      type: Number,
    },
    modalId: {
      required: true,
      type: String,
    },
    product: {
      type: Object as PropType<AdditionalProduct | Recipe>,
      required: true,
    },
    isAdditionalProduct: {
      default: false,
      type: Boolean,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    lazyLoadImages: {
      default: true,
      type: Boolean,
    },
    quantity: {
      default: 0,
      type: Number,
    },
  },
  setup(props) {
    /** Options for modal window. */
    const productModalOptions = computed<ProductModalState>(() => ({
      id: props.modalId || `product-modal-${props.product.id}`,
      product: props.product,
    }));
    const productModal = useProductModalStore();
    const { formatPrice } = useCustomerCurrency();
    const { isModalVisible } = storeToRefs(productModal);

    const openModalOnEnter = (event: KeyboardEvent): void => {
      if (isModalVisible.value || event.code !== 'Enter') return;
      event.preventDefault();
      productModal.open(productModalOptions.value);
    };

    const openModalOnClick = (event: Event): void => {
      const target = event?.target as HTMLElement;
      if (
        target &&
        typeof target.className === 'string' &&
        typeof target.className.includes === 'function' &&
        (target.className.includes('button') ||
          target.className.includes('icon') ||
          target.className.includes('label-text'))
      )
        return;
      event.preventDefault();
      productModal.open(productModalOptions.value);
    };
    return {
      isModalVisible,
      openModalOnClick,
      openModalOnEnter,
      formatPrice,
    };
  },
  render(): VNode | null {
    if (!this.product) return null;
    return (
      <div
        aria-controls={this.modalId}
        aria-expanded={this.isModalVisible}
        onClick={this.openModalOnClick}
        onKeydown={this.openModalOnEnter}
        role="button"
        tabindex={0}
      >
        <div
          class="w-full flex items-center py-2 border-b border-gray-200"
          data-testid={`product-row-${this.product.id}`}
        >
          <div class="w-16 h-16 flex-shrink-0 rounded-sm overflow-hidden">
            <SanityPicture
              alt={this.product.plateImage?.alt ?? this.product.title}
              class="w-full h-full object-cover"
              src={
                this.product.plateImage?.sizes?.thumbnail ??
                this.product.mainImage?.sizes?.thumbnail ??
                placeholderImageUrl
              }
              lazy={this.lazyLoadImages}
            />
          </div>
          <div class="ml-4 flex-1">
            <div class="text-sm font-medium text-rb-dark-green">
              {formatTitle(this.product.title)}
            </div>
            <div class="text-sm text-rb-dark-green">x {this.quantity}</div>
          </div>
          <div class="ml-4 flex flex-col items-start font-bold text-rb-green justify-center">
            {'price' in this.product &&
              this.formatPrice(this.product.price * this.quantity)}
          </div>
        </div>
      </div>
    );
  },
});

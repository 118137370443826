import IconBox from '@/assets/icons/icon-box.svg?component';
import ChevronOpen from '@/assets/icons/icon-chevron-down.svg?component';
import ChevronClose from '@/assets/icons/icon-chevron-up.svg?component';
import Details from '@/components/Details';
import WeekDiscountBadge from '@/components/WeekDiscountBadge';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import { Price, ZERO_PRICE } from '@ruokaboksi/utils';
import type { PropType, VNode } from 'vue';
import type { ProductLineItem } from './DeliveryWeekOverview.d';
import DeliveryWeekOverviewTable from './DeliveryWeekOverviewTable';

export default defineComponent({
  name: 'DeliveryWeekOverview',
  props: {
    view: {
      default: 'desktop',
      type: String as PropType<'desktop' | 'mobile'>,
    },
  },
  setup() {
    const {
      boxType,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      selectedDeliveryWeek,
      selectedFullDeliveryWeek,
      selectedDeliveryWeekDiscount,
      selectedDeliveryWeekCredit,
      selectedDeliveryWeekRecipeCount,
      selectedDeliveryWeekPrice,
    } = useDeliveryWeeks();
    const { weekConfiguration } = useWeekConfigurations();
    const { t } = useI18n();

    const { formatPrice } = useCustomerCurrency();

    const boxInfo = computed<ProductLineItem>(() => {
      const selectedValue = selectedFullDeliveryWeek.value;
      const recipeCount = selectedDeliveryWeekRecipeCount.value;

      // Check that we have all the data we need
      if (!boxType.data.value || !recipeCount || !selectedValue) {
        return {
          label: '',
          price: ZERO_PRICE,
          type: 'box',
        };
      }

      const label = getBoxPriceTierTitle(boxType.data.value, recipeCount);

      const boxPrice = selectedValue.boxPrice;

      const boxPriceExcludePremiumExcludeCredits = Price.fromGrossPrice(
        boxPrice.grossPrice,
        boxPrice.discount
      );

      return {
        label,
        price: boxPriceExcludePremiumExcludeCredits,
        type: 'box',
      };
    });

    const labelForId = (id: string): string => {
      const products = [
        ...weekConfiguration.value.additionalProducts,
        ...weekConfiguration.value.recipes,
      ];
      return products?.find((product) => product?.id === id)?.title ?? '';
    };

    const additionalProducts = computed<ProductLineItem[]>(() =>
      selectedFullDeliveryWeek.value
        ? selectedFullDeliveryWeek.value?.additionalProducts.map(
            ({ id, price }) => ({
              label: labelForId(id) ?? fallbackAdditionalProduct.title,
              price: new Price(price),
              type: 'additional-product',
            })
          )
        : []
    );

    const shippingProducts = computed<ProductLineItem[]>(() =>
      selectedFullDeliveryWeek.value?.shipping[0]?.price.grossPrice
        ? selectedFullDeliveryWeek.value?.shipping.map(({ price }) => ({
            label: t('delivery_week.shipping_sunday_cost_label'),
            price: new Price(price),
            type: 'shipping',
          }))
        : []
    );

    const premiumProducts = computed<ProductLineItem[]>(() =>
      selectedFullDeliveryWeek.value
        ? selectedFullDeliveryWeek.value?.recipes
            .filter((recipe) => recipe.isPremium)
            .map(({ premiumPrice }) => ({
              label: t('delivery_week.premium_recipe_label'),
              price: Price.fromNetPrice(premiumPrice || 0),
              type: 'premium-product',
            }))
        : []
    );

    const discountLines = computed<ProductLineItem[]>(() => {
      const lines: ProductLineItem[] = [];

      // Credits
      if (selectedDeliveryWeekCredit.value) {
        lines.push({
          label: t('delivery_week.credits_label'),
          price: new Price({
            netPrice: 0,
            grossPrice: selectedDeliveryWeekCredit.value,
            discount: 0,
            credit: selectedDeliveryWeekCredit.value,
          }),
          type: 'credit',
        });
      }
      return lines;
    });

    const productLines = computed<ProductLineItem[]>(() => [
      boxInfo.value,
      ...premiumProducts.value,
      ...additionalProducts.value,
      ...shippingProducts.value,
    ]);

    const isDiscountedPrice = computed<boolean>(
      () =>
        selectedDeliveryWeekPrice.value.grossPrice !==
        selectedDeliveryWeekPrice.value.netPrice
    );

    return {
      boxInfo,
      discountLines,
      isDiscountedPrice,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      productLines,
      selectedDeliveryWeek,
      selectedDeliveryWeekDiscount,
      selectedDeliveryWeekPrice,
      formatPrice,
    };
  },
  render(): VNode {
    // If the box price is 0 or less then there is no box or invalid.
    if (this.boxInfo.price.netPrice <= 0) return <div />;

    switch (this.view) {
      case 'mobile':
        return (
          <div class="mx-auto mt-7 max-w-prose lg:hidden" data-view="mobile">
            <DeliveryWeekOverviewTable
              discountLines={this.discountLines}
              productLines={this.productLines}
              total={this.selectedDeliveryWeekPrice.netPrice}
              totalPreDiscounts={this.selectedDeliveryWeekPrice.grossPrice}
            />
          </div>
        );
      default:
        return (
          <Details
            class="mx-auto mb-7 max-w-prose max-lg:hidden"
            data-view="desktop"
            open
            v-slots={{
              summary: () => (
                <summary>
                  <IconBox aria-hidden="true" class="icon-box" />
                  <span class="title">
                    {this.$t('delivery_week_overview.total')}
                  </span>
                  {this.selectedDeliveryWeek ? (
                    <>
                      {this.selectedDeliveryWeek.editable && (
                        <WeekDiscountBadge
                          deliveryWeek={this.selectedDeliveryWeek}
                        />
                      )}
                      <strong class="details-total price">
                        {this.formatPrice(
                          this.selectedDeliveryWeekPrice.netPrice
                        )}
                      </strong>
                    </>
                  ) : null}
                  <ChevronOpen aria-hidden="true" class="icon icon-open" />
                  <ChevronClose aria-hidden="true" class="icon icon-close" />
                </summary>
              ),
            }}
          >
            <DeliveryWeekOverviewTable
              discountLines={this.discountLines}
              productLines={this.productLines}
              total={this.selectedDeliveryWeekPrice.netPrice}
              totalPreDiscounts={this.selectedDeliveryWeekPrice.grossPrice}
              class="!w-100 mx-auto mb-4 mt-4"
            />
          </Details>
        );
    }
  },
});

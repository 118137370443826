import LoadingEggs from '@/components/LoadingEggs';
import ProductCard from '@/components/ProductCard';
import SelectedProductsTable from '@/components/SelectedProductsTable';
import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import {
  type Price,
  Week,
  ZERO_PRICE,
  calculateAdditionalProductPrice,
  makeStartOfDayUTC,
} from '@ruokaboksi/utils';
import { NuxtLink } from '#components';
import './SelectedProducts.css';
import ProductSwiper from './ProductSwiper/ProductSwiper';

export default defineComponent({
  name: 'SelectedProducts',
  setup() {
    const {
      isLoadingDeliveryWeeks,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      selectedDeliveryWeek,
      deliveryWeeks,
      selectedFullDeliveryWeek,
    } = useDeliveryWeeks();
    const { isLoadingWeekConfigurations, weekConfiguration } =
      useWeekConfigurations();
    const { subscription } = useCustomerSubscriptions();
    const { t } = useI18n();

    const { market } = useCurrentMarket();
    const isFinnishMarket = computed(() => market.value === 'FIN');

    const selectedRecipes = computed<Recipe[]>(() =>
      selectedFullDeliveryWeek.value?.recipes?.length &&
      weekConfiguration.value?.recipes?.length
        ? selectedFullDeliveryWeek.value.recipes.map(
            (recipe) =>
              weekConfiguration.value.recipes.find(
                (r) => r.id === recipe?.id
              ) ?? {
                ...fallbackRecipe,
                title: t(fallbackRecipe.title),
                id: recipe.id,
                taxRate: recipe.taxRate,
                price: recipe.premiumPrice,
                isPremium: recipe.isPremium,
              }
          )
        : []
    );

    const selectedAdditionalProducts = computed<AdditionalProduct[]>(() =>
      selectedFullDeliveryWeek.value?.additionalProducts?.length &&
      weekConfiguration.value?.additionalProducts?.length
        ? selectedFullDeliveryWeek.value.additionalProducts.map(
            (product) =>
              weekConfiguration.value.additionalProducts.find(
                (r) => r.id === product?.id
              ) ?? {
                ...fallbackAdditionalProduct,
                title: t(fallbackAdditionalProduct.title),
                id: product.id,
                price: product.price.netPrice,
                sku: product.id,
                taxRate: product.taxRate,
              }
          )
        : []
    );

    const priceForAdditionalProduct = (
      product?: AdditionalProduct | null
    ): Price => {
      if (!product || !subscription.value) {
        return ZERO_PRICE;
      }

      return calculateAdditionalProductPrice({
        additional: { ...product, frozenProductPrice: product.price ?? 0 },
        campaign: selectedFullDeliveryWeek.value?.activeCampaign ?? null,
        now: Week.fromPaymentDate(
          makeStartOfDayUTC(selectedFullDeliveryWeek.value?.paymentDate),
          subscription.value.defaultDeliverySlot
        ),
      });
    };

    const buttonTextRecipes = computed<string>(() =>
      isSelectedDeliveryWeekEditable.value
        ? `${t('selected_products.change_week')} ${
            selectedDeliveryWeek.value?.weekNumberString
          } ${t('selected_products.recipes')}`
        : `${t('selected_products.open')} ${
            selectedDeliveryWeek.value?.weekNumberString
          } ${t('selected_products.recipes')}`
    );

    const buttonTextAdditionalProducts = computed<string>(() => {
      if (!isSelectedDeliveryWeekEditable.value) {
        return `${t('selected_products.open')} ${
          selectedDeliveryWeek.value?.weekNumberString
        } ${t('selected_products.additional_products')}`;
      }

      if (selectedAdditionalProducts.value.length === 0) {
        return `${t(
          'selected_products.add_additional_products'
        )} ${selectedDeliveryWeek.value?.weekNumberString}`;
      }

      return `${t('selected_products.change')} ${
        selectedDeliveryWeek.value?.weekNumberString
      } ${t('selected_products.additional_products')}`;
    });

    return {
      buttonTextAdditionalProducts,
      buttonTextRecipes,
      isFinnishMarket,
      isLoadingDeliveryWeeks,
      isLoadingWeekConfigurations,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPaused,
      priceForAdditionalProduct,
      selectedAdditionalProducts,
      selectedFullDeliveryWeek,
      selectedRecipes,
      deliveryWeeks,
      t,
    };
  },
  render(): VNode | null {
    if (this.isLoadingDeliveryWeeks) {
      return <LoadingEggs loadingTextKey={this.$t('index_page.loading')} />;
    }
    return (
      <div class="selected-products">
        <div class="lg:hidden">
          {this.selectedRecipes.length ||
          this.selectedAdditionalProducts.length ? (
            <>
              {this.selectedRecipes.length ? (
                <h2 class="sr-only">{this.$t('selected_products.selected')}</h2>
              ) : null}
              <ProductSwiper
                class={{ paused: this.isSelectedDeliveryWeekPaused }}
                selectedRecipes={this.selectedRecipes}
                selectedAdditionalProducts={this.selectedAdditionalProducts}
              />
            </>
          ) : this.isLoadingDeliveryWeeks ||
            this.isLoadingWeekConfigurations ? (
            <div class="no-data">
              <p class="motion-safe:animate-bounce">
                {this.$t('global.loading')}
              </p>
            </div>
          ) : (
            <div class="no-data">
              <p>{this.$t('selected_products.no_selections')}</p>
            </div>
          )}

          <div class="product-list">
            {this.selectedRecipes.map((recipe, index) => (
              <div class="product-list-item recipe">
                <div class="index">{index + 1}</div>
                <div class="title">{recipe?.title}</div>
              </div>
            ))}
            <div class="relative my-6 text-center">
              <NuxtLink class="button button-primary" to={keepQuery('recipes')}>
                {this.buttonTextRecipes}
              </NuxtLink>
            </div>
            {this.isFinnishMarket && (
              <div>
                <h2 class="heading">
                  {this.t('selected_products.additional_products_heading')}
                </h2>

                <div
                  class="mt-6"
                  data-card-count={
                    Object.keys(
                      this.selectedAdditionalProducts.reduce(
                        (acc, product) => {
                          acc[product.id] = (acc[product.id] || 0) + 1;
                          return acc;
                        },
                        {} as Record<string, number>
                      )
                    ).length
                  }
                >
                  {Object.entries(
                    this.selectedAdditionalProducts.reduce(
                      (acc, product) => {
                        acc[product.id] = acc[product.id] || {
                          ...product,
                          quantity: 0,
                        };
                        acc[product.id].quantity += 1;
                        return acc;
                      },
                      {} as Record<
                        string,
                        AdditionalProduct & { quantity: number }
                      >
                    )
                  ).map(([productId, product]) => (
                    <SelectedProductsTable
                      key={productId}
                      data-type="additional-product"
                      discount={
                        this.priceForAdditionalProduct(product).discount
                      }
                      product={product}
                      modalId={`product-modal-${product.id}`}
                      isAdditionalProduct={true}
                      lazyLoadImages={true}
                      quantity={product.quantity}
                    />
                  ))}
                </div>

                <div class="my-12 mt-6 text-center">
                  <NuxtLink
                    class="button button-primary"
                    to={keepQuery('additional-products')}
                  >
                    {this.buttonTextAdditionalProducts}
                  </NuxtLink>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="max-lg:hidden">
          <h2
            class={{
              'product-header': true,
              paused: this.isSelectedDeliveryWeekPaused,
            }}
          >
            {this.$t('selected_products.selected_recipes')}
          </h2>
          {this.selectedRecipes.length ? (
            <div
              class="product-cards"
              data-card-count={this.selectedRecipes.length}
            >
              {this.selectedRecipes.map((recipe, index) => (
                <ProductCard
                  data-type="recipe"
                  editable={false}
                  hideActions={true}
                  index={index + 1}
                  key={index}
                  isAdditionalProduct={false}
                  modalId={`product-modal-${recipe?.id}`}
                  paused={this.isSelectedDeliveryWeekPaused}
                  product={recipe}
                  selected={true}
                  lazyLoadImages={true}
                />
              ))}
            </div>
          ) : this.isLoadingDeliveryWeeks ||
            this.isLoadingWeekConfigurations ? (
            <div class="no-data">
              <p class="motion-safe:animate-bounce ">
                {this.$t('global.loading')}
              </p>
            </div>
          ) : (
            <div class="no-data">
              <p>{this.$t('selected_products.no_selections')}</p>
            </div>
          )}
          <div class="my-12 text-center">
            <NuxtLink class="button button-primary" to={keepQuery('recipes')}>
              {this.buttonTextRecipes}
            </NuxtLink>
          </div>
          {this.isFinnishMarket && (
            <div>
              <h2 class="heading">
                {this.t('selected_products.additional_products_heading')}
              </h2>

              <div
                class="mt-6"
                data-card-count={
                  Object.keys(
                    this.selectedAdditionalProducts.reduce(
                      (acc, product) => {
                        acc[product.id] = (acc[product.id] || 0) + 1;
                        return acc;
                      },
                      {} as Record<string, number>
                    )
                  ).length
                }
              >
                {Object.entries(
                  this.selectedAdditionalProducts.reduce(
                    (acc, product) => {
                      acc[product.id] = acc[product.id] || {
                        ...product,
                        quantity: 0,
                      };
                      acc[product.id].quantity += 1;
                      return acc;
                    },
                    {} as Record<
                      string,
                      AdditionalProduct & { quantity: number }
                    >
                  )
                ).map(([productId, product]) => (
                  <SelectedProductsTable
                    key={productId}
                    data-type="additional-product"
                    discount={this.priceForAdditionalProduct(product).discount}
                    product={product}
                    modalId={`product-modal-${product.id}`}
                    isAdditionalProduct={true}
                    lazyLoadImages={true}
                    quantity={product.quantity}
                  />
                ))}
              </div>

              <div class="my-12 mt-6 text-center">
                <NuxtLink
                  class="button button-primary"
                  to={keepQuery('additional-products')}
                >
                  {this.buttonTextAdditionalProducts}
                </NuxtLink>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
});

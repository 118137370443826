import Button from '@/components/Button';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'LoyaltyProgramBannerActivate',
  setup() {
    const { localeProperties } = useI18n();
    const loyaltyLogo = computed(() =>
      getLoyaltyLogo(localeProperties.value.brand)
    );
    return {
      loyaltyLogo,
    };
  },
  render(): VNode {
    return (
      <div class="flex w-full items-end">
        <div class="flex flex-1 flex-col items-start gap-1 overflow-x-hidden">
          <span class="text-tiny text-nowrap text-white">
            {this.$t('loyalty_banner.slogan')}
          </span>
          {this.loyaltyLogo && <this.loyaltyLogo height="1.5rem" />}
        </div>

        <Button class="button-stroke-white !h-8 !min-h-8 text-white">
          {this.$t('loyalty_banner.button_text')}
          {' >'}
        </Button>
      </div>
    );
  },
});
